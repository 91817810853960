import React from 'react';
import { Routes, Route} from 'react-router-dom';
import HomePage from './pages/HomePage';
import ImplementationsPage from './pages/ImplementationsPage';
import InstructionsPage from './pages/InstructionsPage';
import ContactPage from './pages/ContactPage';
import GdprPage from './pages/GdprPage';
import CookiesPage from './pages/CookiesPage';
import usePageTracking from './components/usePageTracking';

import './styles/main.scss';

const App = () => {
  usePageTracking();
  return (
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route exact path="/implementations" element={<ImplementationsPage />} />
          <Route exact path="/instructions" element={<InstructionsPage />} />
          <Route exact path="/instructions/:id" element={<InstructionsPage />} />
          <Route exact path="/gdpr" element={<GdprPage />} />
          <Route exact path="/cookies" element={<CookiesPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
      </Routes>
  );
}

export default App;
